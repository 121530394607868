<template>
  <div class="gnb flexB new-gnb" ref="gnbContainer" v-if="showGnb" style="display:flex;justify-content: space-between; position:relative;">
    <h2 v-if="showLogo" class="top-bar-site-logo" style="margin: 0;">
      <div style="width: 190px; margin-left:30px;">
        <a href="javascript:void(0);">
          <img :src="require(`/src/assets${getImageSrc(accountId , 'gnb')}`)" style="width: 100%;">
        </a>
      </div>
      <div class="top-system-title">
        <img v-if="getImageSrc(accountId , 'gnbTopImage') !== null"
             :src="require(`/src/assets${getImageSrc(accountId , 'gnbTopImage')}`)">
        <!-- acoountId 별로 문구 지정 -->
        <h2 v-if="getContentString(this.accountId , 'title') !== null ">
          {{getContentString(this.accountId , 'title')}}
        </h2>
      </div>
    </h2>
    <div class="loginInfo flex">
        <div class="userName" @click="navigateToProfileEdit">
          <img src="@/assets/images/ico_user.png" alt="user" />
          <div class="userInfo">
            <p>{{ name }}</p>
            <p>{{ loginId }}</p>
          </div>
        </div>
      <button class="table logoutBtn" @click="logout">{{ $t("btn-logout") }}</button>
    </div>
  </div>
</template>
<style lang="scss">
// .new-gnb{
//   width: 350px;
//   min-width: 350px;
//   height: 60px;
//   background: #0d1118;
//   font-size: 1.2rem;
//   color: #808080;
//   padding: 0 20px 0 20px;
//   border-bottom: 0px solid #d3d3d3;
//   position: absolute;
//   bottom: 0;
//   top: auto;
//   left: 0;
//   z-index: 1000;
//   align-items: center;
//   display: block !important;
// }
// .loginInfo{width: 100%;display: flex;justify-content: space-between !important;align-items: center;}
.userName{display: flex;align-items: center;justify-content: flex-start;}
// .userName > img{width: 35px !important;height: 35px !important;}
// .userName > .userInfo{display: flex;flex-direction: column;}
// .userName > .userInfo > p{color: #fff;}

.head_selector{
  width: 30%;
}
.top-bar-site-logo{
  display: flex;
    align-items: center;
    margin: 0;
    letter-spacing: -1px;
}
.top-system-title{
  margin-left: 60px;
  width: 250px;
  padding-top: 0px;
  img{
    width: max-content;
  }
  h2{
    font-size: 1.8rem;
    font-weight: 700;
    color: #101010;
    margin: 0;
  }
}
.btBox {
  text-align: center;
  position: relative;
  margin-right: 10px;

  .el-select-dropdown__list {
    padding: 0 !important;
    .el-select-dropdown__item {
      padding: 0 !important;
      line-height: unset;
      height: unset;
      &:last-child {
        padding-bottom: 20px !important;
      }
    }
  }
  .el-input__inner {
    font-size: 1.2rem;
    width: 110px;
    height: 24px;
    line-height: 24px !important;
    border-radius: 12px;
    border: 1px solid #0790FF !important;
    color: #0790FF;
    margin-bottom: 1rem;
    &::placeholder {
      color: #0790FF;
    }
  }

  span.el-input__suffix{
    background: transparent;
    right: 27px !important;
    .el-input__suffix-inner{
     background: transparent !important;
    }
  }


  .el-input__icon {
    height: 24px;
    line-height: 24px;
    color: #0790FF !important;
  }
  .bt_logo {
    //width: 9rem;
    margin: 1rem auto;
    display: block;
  }
  a {
    font-size: 1.2rem;
    display: block;
    width: 100%;
    //border-top: 1px solid #fff;
  }
}

</style>
<script>
import { mapState } from "vuex";
import moment from "moment";
import {getImageSrc , getContent} from "@/util/common";


export default {
  data() {
    return {
      moment: moment,
      subMenu: "",
      menuName:"",
      option: [],
    };
  },
  computed: {
    ...mapState({
      isLogin : state => state.store.isLogin,
      name : state => state.store.name,
      accountId : state => state.store.accountId,
      loginId : state => state.store.loginId,
      ownerWork : state => state.store.ownerWork,
      options : state => state.store.options,
      navbarState : state => state.store.navbarState,
      subMenuState : state => state.store.subMenuState,
      departmentName : state => state.store.departmentName,
      currentLang : state => state.store.currentLang,
      isAdmin : state => state.store.isAdmin,
      userType : state => state.store.userType,
    }),
    currentLang: {
      get() {
        return this.$store.state.store.currentLang;
      },
      set(value) {
        this.$store.commit("store/langChange", value);

        //TODO 언어 변경시 이동 제거
        //this.$router.go();
      },
    },
    showLogo() {
      return this.$route.name === 'liveListControlUser'; // Ensure this matches your route's name
    },
    showGnb() {
      const hideLnbRoutes = ['DroneStatusMap']; // GNB가 보이지 않아야 하는 뷰 이름들
      return !hideLnbRoutes.includes(this.$route.name);
    }
  },
  mounted() {
    this.handleMenu();
    //mouseover 상태바(아래 URL) 없애기
    this.$nextTick(function (){
      // var atags = this.$refs.gnbContainer.querySelectorAll('a');

      // atags.forEach(el => el.addEventListener('mouseover', function() {
      //   var href = this.getAttribute('href') || this.getAttribute("data-href");
      //   this.addEventListener('click', function () {
      //     window.location.href = href;
      //   })
      //   this.setAttribute('data-href', href)//href에 지정된 링크 연결을 유지 시킵니다.
      //   this.removeAttribute('href');
      // }))
    })

  },
  watch: {
    $route (to){
      this.menuName = to.title;
    },
    subMenuState: function () {
      this.handleMenu();
    },
    navbarState: function () {
      this.handleMenu();
    },
  },
  filters: {
    moment : function (date){
      //브라우저 언어설정에 따른 양식 변화
      return moment(date).lang(navigator.language).format('LL');
    }
  },
  methods: {
    getHomeUrl(){
      var resultUrl = `/${this.accountId}/eventControl/liveListControlUser`;

      return resultUrl
    },
    handleRefresh() {
      this.$router.go(this.$router.currentRoute);
    },
    handleMenu() {
      this.option = this.options[this.navbarState - 1];
      this.subMenu = this.option[this.subMenuState].label;
    },
    handleSubMenu() {
      this.$router.push(this.subMenu);

      // var container = document.querySelectorAll(".el-menu-item");
      // var index = this.navbarState - 1;
      // var menu = container.item(index);
      // if (menu.classList.contains("router-link-active") == false) {
      //   menu.classList.add("router-link-active");
      // }
    },
    logout() {
      let id = this.accountId;
      this.$store.dispatch("store/LOGOUT");
      this.$router.push(`/${id}/login`);
    },
    navigateToProfileEdit() {
      this.$router.push({ name: 'profileEdit' });
    },
    getImageSrc(accountId , type){
        return getImageSrc(accountId,type)
    },
    getContentString(accountId , type){
       return getContent(accountId,type)
    },
  },
};
</script>
