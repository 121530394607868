<template>
  <div class="lnb" v-if="showLnb" :style="[isAdmin ? {'background' : '#000'} : {'background' : '#081c2c'}]" ref="lnbNav" style="display: block;">
    <!-- <button class="expandBtn" @click="handleOpen">
      <img
          src="@/assets/images/icon_svg/ic_expand_arrow.svg"
          alt="expand"
          class="active"
      />
      <img
          src="@/assets/images/icon_svg/ic_collapse_arrow.svg"
          class="normal"
          alt="collapse"
      />
    </button> -->

    <el-row class="mainMenu" v-bind:class="[isAdmin ? 'mainMenu_admin':'mainMenu']">
      <div class="lnbTop" >
        <router-link :to="`/${accountId}/eventControl/liveListControlUser`" style="display: block;margin-bottom: 30px;">
          <img v-if="accountId == 'komipo'" src="@/assets/images/LNB_new_rw_logo.png" alt="logo" style="width: 194px;"/>
          <div v-else style="width: 240px; text-align: center; padding: 0 14px;">
            <img src="@/assets/images/LNB_admin_logo.png" alt="logo" style="width: 90%;"/>
          </div>
<!--          <img v-else src="@/assets/images/LNB_new_logo.png" alt="logo" />-->
<!--          <img src="@/assets/images/ic_logo_dronework_wh.svg" alt="logo" style="width: 20rem;"/>-->
<!--          <img v-else src="@/assets/images/ic_user_logo_wh.svg" alt="logo" style="width: 20rem; fill: white;"/>-->
<!--          <h1>드론운영관제시스템</h1>
          <img src="@/assets/images/ic_logo_dronework.svg" alt="logo" />-->
        </router-link>
<!--        <span class="subTitle">DASHBOARD</span>-->
        <span class="subTitle" v-if="isAdmin">{{$t("lnb-admin-mark")}}</span>
        <span class="subTitle" v-else-if="!isAdmin">{{$t("lnb-manager-mark")}}</span>
      </div>
      <el-col :span="12">
        <el-menu
            :router="false"
            :default-active="selectIndex"
            :unique-opened="true"
            class="el-menu-vertical-demo"
            @open="leftOpen"
            @close="leftClose"
            style="border: 0;"
        >
          <!-- 종합상황판 메뉴 분리(삭제X) -->
          <el-submenu index="1" v-if="isUserType =='Manager'" style="display: none;">
            <template slot="title">
              <img src="@/assets/images/icon_svg/LNB_dashboard.svg" alt="icon" />
              <span> {{ $t("top-select-total-drone-status-map") }} </span>
            </template>
            <el-menu-item index="overallManager" >
              <router-link :to="`/${accountId}/dashboard/overallManager`" >{{ $t("top-select-total-view-dashboard") }}</router-link>
            </el-menu-item>
            <el-menu-item index="DroneStatusMap">
              <router-link :to="`/${accountId}/dashboard/DroneStatusMap`">{{ $t("top-select-total-drone-map-monitoring") }}</router-link>
            </el-menu-item>
          </el-submenu>

          <!--  통합 모니터링  -->
          <el-menu-item v-if="isUserType =='Manager'" index="DroneStatusMap" >
            <router-link :to="`/${accountId}/dashboard/DroneStatusMap`" style="display: block; appearance:menulist-button;">
              <img src="@/assets/images/icon_svg/LNB_dashboard.svg" alt="icon" />
              <span> {{ $t("top-select-total-drone-map-monitoring") }}</span>
            </router-link>
          </el-menu-item>

          <!-- 종합요약정보 -->
          <el-menu-item v-if="isUserType =='Manager'" index="overallManager" >
            <router-link :to="`/${accountId}/dashboard/overallManager`" style="display: block; appearance:menulist-button;">
              <img src="@/assets/images/icon_svg/LNB_info_dashboard.svg" alt="icon" />
              <span> {{ $t("top-select-total-view-dashboard") }}</span>
            </router-link>
          </el-menu-item>

          <!-- 매니저 현장상황판 -->
          <el-menu-item v-if="isUserType =='Manager'" index="SiteControl" >
            <router-link :to="`/${accountId}/eventControl/liveListControl`" style="display: block">
              <img src="@/assets/images/icon_svg/LNB_site_dashboard.svg" alt="icon" />
              <span> {{ $t("overall-site-control") }}</span>
            </router-link>
          </el-menu-item>

          <!--  관리자 홈  -->
          <el-menu-item v-if="isAdmin" index="overallAdmin" >
            <router-link :to="`/${accountId}/dashboard/overallAdmin`" style="display: block;">
              <img src="@/assets/images/icon_svg/LNB_dashboard.svg" alt="icon" />
              <span> {{$t("top-select-total-view-dashboard")}} </span>
            </router-link>
          </el-menu-item>

          <!-- 사용자 홈 -->
<!--          <el-menu-item v-if="!isAdmin" index="SiteControlUser" >
            <router-link :to="`/${accountId}/eventControl/liveListControlUser`" style="display: block">
              &lt;!&ndash;              <img v-if="isAdmin" src="@/assets/images/icon_svg/LNB_site_dashboard.svg" alt="icon"/>&ndash;&gt;
              <img src="@/assets/images/icon_svg/LNB_dashboard.svg" alt="icon" />
              &lt;!&ndash;              <span v-if="isAdmin"> {{$t("overall-site-control")}} </span>&ndash;&gt;
              <span > {{$t("overall-site-control-user")}} </span>
            </router-link>
          </el-menu-item>-->
<!--          <el-menu-item v-if="!isAdmin" index="DroneStatusMap" >
            <router-link :to="`/${accountId}/dashboard/DroneStatusMap`" style="display: block">
              &lt;!&ndash;              <img v-if="isAdmin" src="@/assets/images/icon_svg/LNB_site_dashboard.svg" alt="icon"/>&ndash;&gt;
              <img src="@/assets/images/icon_svg/LNB_site_dashboard.svg" alt="icon" />
              &lt;!&ndash;              <span v-if="isAdmin"> {{$t("overall-site-control")}} </span>&ndash;&gt;
              <span > {{ $t("top-select-total-drone-status-map") }} </span>
            </router-link>
          </el-menu-item>-->

<!--          <el-submenu index="2" v-if="isUserType =='Manager'">
            <template slot="title">
              <img src="@/assets/images/icon_svg/LNB_vod_manage.svg" alt="icon" />
              <span> 종합 운영데이터 </span>
            </template>
            <el-menu-item index="SiteControl" style="display: none;">
              <router-link :to="`/${accountId}/eventControl/liveListControl`">{{ $t("overall-site-control") }}</router-link>
            </el-menu-item>
            <el-menu-item index="dashChannelList" style="display: none;">
              <router-link :to="`/${accountId}/dashboard/dashChannelList`">{{ $t("top-select-total-view-live") }}</router-link>
            </el-menu-item>
            <el-menu-item index="dashDeviceList">
              <router-link :to="`/${accountId}/dashboard/dashDeviceList`">{{ $t("overall-drone-title") }}</router-link>
            </el-menu-item>
            <el-menu-item index="dashContentsList">
              <router-link :to="`/${accountId}/dashboard/dashContentsList`">{{ $t("top-select-total-view-vod") }}</router-link>
            </el-menu-item>
          </el-submenu>-->

          <!-- 매니저 vod -->
          <el-menu-item v-if="isUserType =='Manager'" index="dashContentsList" >
            <router-link :to="`/${accountId}/dashboard/dashContentsList`" style="display: block">
              <img src="@/assets/images/icon_svg/LNB_vod_manage.svg" alt="icon" />
              <span> {{ $t("site-control-contents-sub-vod") }} </span>
            </router-link>
          </el-menu-item>


          <!-- 디바이스 운영이력 -->
          <el-menu-item v-if="isUserType =='Manager'" index="dashDeviceList" >
            <router-link :to="`/${accountId}/dashboard/dashDeviceList`" style="display: block">
              <img src="@/assets/images/icon_svg/LNB_drone_log.svg" alt="icon" />
              <span> {{ $t("overall-drone-title") }} </span>
            </router-link>
          </el-menu-item>


          <el-menu-item index="event" v-if="isAdmin || isUserType =='Manager'">
            <router-link :to="`/${accountId}/event/list`" style="display: block;">
              <img src="@/assets/images/icon_svg/LNB_site_management.svg" alt="icon" />
              <span> {{ $t("top-select-site-manager") }} </span>
            </router-link>
          </el-menu-item>

          <el-submenu index="4" v-if="isAdmin">
            <template slot="title">
              <img src="@/assets/images/icon_svg/LNB_drone.svg" alt="icon" />
              <span> {{ $t("top-select-device-manager") }} </span>
            </template>
            <el-menu-item index="deviceList">
              <router-link :to="`/${accountId}/device/deviceList`">{{ $t("top-select-device-manager-drone") }}</router-link>
            </el-menu-item>
            <el-menu-item index="deviceLogList">
              <router-link :to="`/${accountId}/device/deviceLogList`">{{ $t("top-select-device-manager-details") }}</router-link>
            </el-menu-item>
          </el-submenu>



          <el-submenu index="7" v-if="isAdmin || isUserType =='Manager'" >
            <template slot="title">
              <img src="@/assets/images/icon_svg/LNB_user_group.svg" alt="icon" />
              <span> {{$t("top-select-user-dept-manager")}} </span>
            </template>
            <el-menu-item index="userList">
              <router-link :to="`/${accountId}/user/userList`">{{ $t("top-select-user-manager") }}</router-link>
            </el-menu-item>
            <el-menu-item index="teamList">
              <router-link :to="`/${accountId}/user/teamList`">{{ $t("top-select-user-manager-dept") }}</router-link>
            </el-menu-item>
            <el-menu-item index="accessLogList">
              <router-link :to="`/${accountId}/user/accessLogList`">{{ $t("top-select-user-manager-member-log") }}</router-link>
            </el-menu-item>
          </el-submenu>

          <el-menu-item index="station" v-if="isAdmin || isUserType =='Manager'">
            <router-link :to="`/${accountId}/station/list`" style="display: block;">
              <img src="@/assets/images/icon_svg/LNB_site_station.svg" alt="icon" />
              <span> {{ $t("top-select-station-data") }} </span>
            </router-link>
          </el-menu-item>

<!--          <el-menu-item index="userConfirmList" v-if="isUserType =='Manager'">
            <router-link :to="`/${accountId}/user/userConfirmList`" style="display: block;">
              <img src="@/assets/images/icon_svg/LNB_group.svg" alt="icon" />
              <span> {{$t("top-select-user-confirm-manager")}} </span>
            </router-link>
          </el-menu-item>-->

          <el-menu-item index="contentsList" v-if="isAdmin">
            <router-link :to="`/${accountId}/contents/contentsList`" style="display: block;">
              <img src="@/assets/images/icon_svg/LNB_vod_manage.svg" alt="icon" />
              <span> {{ $t("top-select-vod-manager-contents") }} </span>
            </router-link>
          </el-menu-item>


          <!--VOD 메뉴 숨김-->
<!--          <el-submenu index="8" v-if="isAdmin" style="display: none">
            <template slot="title">
              <img src="@/assets/images/icon_svg/LNB_vod_manage.svg" alt="icon" />
              <span> VOD </span>
            </template>
            <el-menu-item index="contentsList">
              <router-link :to="`/${accountId}/contents/contentsList`">{{ $t("top-select-vod-manager-contents") }}</router-link>
            </el-menu-item>
          </el-submenu>-->

          <el-submenu index="9" v-if="isAdmin">
            <template slot="title">
              <img src="@/assets/images/icon_svg/LNB_live_manage.svg" alt="icon" />
              <span> {{ $t("top-select-live-data-ch") }} </span>
            </template>
            <el-menu-item index="channelList">
              <router-link :to="`/${accountId}/channel/channelList`">{{ $t("top-select-live-data-ch-list") }}</router-link>
            </el-menu-item>
            <el-menu-item index="channelLogList">
              <router-link :to="`/${accountId}/channel/channelLogList`">{{ $t("top-select-live-data-allocation") }}</router-link>
            </el-menu-item>
          </el-submenu>

          <!--통계 메뉴-->
          <el-submenu index="3" v-if="isAdmin || isUserType =='Manager'">
            <template slot="title">
              <img src="@/assets/images/icon_svg/LNB_data.svg" alt="icon" />
              <span> {{$t("site-statistics-title")}} </span>
            </template>
            <el-menu-item index="eventStatistics">
              <router-link :to="`/${accountId}/statistics/eventStatistics`">{{ $t("top-select-site-statistics-site") }}</router-link>
            </el-menu-item>
            <el-menu-item index="eventUserStatistics">
              <router-link :to="`/${accountId}/statistics/eventUserStatistics`">{{ $t("top-select-site-statistics-member") }}</router-link>
            </el-menu-item>
            <el-menu-item index="addressEventStatistics">
              <router-link :to="`/${accountId}/statistics/addressEventStatistics`">{{ $t("top-select-site-statistics-direction") }}</router-link>
            </el-menu-item>
          </el-submenu>

        </el-menu>
      </el-col>
      <div class="btBox">
        <!--        <el-select-->
        <!--            v-model="currentLang"-->
        <!--            placeholder="LANGUAGE"-->
        <!--            id="langSelect"-->
        <!--            @change="langChange"-->
        <!--        >-->
        <!--          <el-option label="한국어" value="ko"> </el-option>-->
        <!--          <el-option label="English" value="en"> </el-option>-->
        <!--        </el-select>-->
        <!-- 산림청 logo -->
        <a  v-if="accountId == 'forest'"  href="https://www.forest.go.kr/" target="_blank">
          <img
              src="../assets/images/LNB_logo_korea_forest.png"
              alt="logo"
              class="bt_logo"
              style="border-radius: 4px;"
          />
        </a>
        <a  class="teixonLogo" v-if="accountId == 'forest'" href="https://www.teixon.com/" target="_blank" style="text-align: right; padding: 0 10px; margin-bottom: 10px;">
          <span style="font-size: 12px;">Software by</span>
          <img
              src="../assets/images/teixon_new_logo_wh.svg"
              alt="logo"
              style="width: 80px;margin: 0 6px; padding-bottom: 4px;"
          />
        </a>

        <!-- teixon logo -->
        <a v-if="accountId != 'forest'" href="http://www.teixon.com" target="_blank" style="border-top: 1px solid rgb(71, 87, 117);">
          <img
              src="../assets/images/LNB_bottomLogo.svg"
              alt="logo"
              class="bt_logo"
              style="width: 9rem;"
          /></a>
      </div>
    </el-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import lang from "@/mixins/lang";
export default {
  mixins: [lang],
  data() {
    return {
      isCollapse: false,
      label: "",
      selectIndex : null,
      isUserType: null
    };
  },
  computed: {
    ...mapState({
      navbarState: state => state.store.navbarState,
      subMenuState: state => state.store.subMenuState,
      accountId: state => state.store.accountId,
      isAdmin: state => state.store.isAdmin,
      userType: state => state.store.userType,
    }),
    currentLang: {
      get() {
        return this.$store.state.store.currentLang;
      },
      set(value) {
        this.$store.commit("store/langChange", value);

        //TODO 언어 변경시 이동 제거
        //this.$router.go();
      },
    },
    showLnb() {
      const hideLnbRoutes = ['liveListControlUser' , 'DroneStatusMap']; // LNB가 보이지 않아야 하는 뷰 이름들
      return !hideLnbRoutes.includes(this.$route.name);
    }
  },
  created() {
    this.isUserType = this.userType;
  },
  watch:{
    userType(value){
      this.isUserType = value;
    },
    selectIndex(value){
      switch (value) {
        case "SiteMonitoring":
          this.selectIndex = "SiteMonitoring"
          break;
        case "SiteContents":
          this.selectIndex = "SiteContents"
          break;
        case "SiteMissionFlight":
          this.selectIndex = "SiteMissionFlight"
          break;
        case "liveListControl":
          this.selectIndex = "SiteControl"
          break;
/*        case "liveListControlUser":
          this.selectIndex = "SiteControlUser"
            this.handleOpen()
          break;*/
        case "liveInfoMonitoring":
          this.selectIndex = "SiteMonitoring"
          break;
        case "liveInfoContents":
          this.selectIndex = "SiteContents"
          break;
        case "liveInfoMission":
          this.selectIndex = "SiteMissionFlight"
          break;
        case "eventRegister" :
        case "eventList" :
          this.selectIndex = "event"
          break;
        case "stationRegister" :
        case "stationList" :
          this.selectIndex = "station"
          break;
        case "channelDetail" :
          this.selectIndex = "channelList"
          break;
        case "contentsDetail" :
          this.selectIndex = "contentsList"
          break;
        case "deviceRegister" :
          this.selectIndex = "deviceList"
          break;
        case "userList" :
        case "userRegister" :
          this.selectIndex = "userList"
          break;
        case "teamList" :
        case "teamRegister" :
          this.selectIndex = "teamList"
          break;

        case "userConfirmList" :
          this.selectIndex = "userConfirmList"
          break;

      }
    },
    $route (to){
      this.selectIndex = to.name;
      //선택한 메뉴명 확인
    }
  },
  mounted(){
    this.selectIndex = this.$route.name;

    //mouseover 상태바(아래 URL) 없애기
    this.$nextTick(function (){
      // var atags = this.$refs.lnbNav.querySelectorAll('a');

      // atags.forEach(el => el.addEventListener('mouseover', function() {
      //   var href = this.getAttribute('href') || this.getAttribute("data-href");
      //   this.addEventListener('click', function () {
      //     window.location.href = href;
      //   })
      //   this.setAttribute('data-href', href)//href에 지정된 링크 연결을 유지 시킵니다.
      //   this.removeAttribute('href');
      // }))
    })

  },
  methods: {
    getHomeUrl(){
      var resultUrl = ""

      switch (this.userType){
        case "Admin" :
          resultUrl = `/${this.accountId}/dashboard/overallAdmin`;
          break;
        case "Manager" :
          resultUrl = `/${this.accountId}/dashboard/DroneStatusMap`;
          break;
        case "User" :
          resultUrl = `/${this.accountId}/eventControl/liveListControlUser`;
          break;
      }
      /*if(!this.$store.state.store.userType == "Manager"){
        resultUrl = `/${this.accountId}/eventControl/liveListControlUser`;
      }*/
      return resultUrl
    },
    handleOpen() {
      this.isCollapse = !this.isCollapse;
      document.querySelector(".lnb").classList.toggle("expand");
      document.querySelector(".expandBtn").classList.toggle("expand");
    },
    leftOpen() {
      if(this.isCollapse == true){
        this.handleOpen()
      }
    },
    leftClose() {
    },
  },
};
</script>
